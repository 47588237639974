
//ONLOAD
jQuery(document).ready(function( $ ){
// START JQUERY
	// COOKIE BAR
	cookiebar_activate = (typeof cookiebar_activate !== 'undefined')? cookiebar_activate : null;
	if(cookiebar_activate) {
		$.cookieBar(cookiebar_activate);
		//ANALYTICS DATA
		if(jQuery.cookieBar('cookies')){load_google_analitics();}
	}
	// SMOOTH SCROLL [href*="#"]:not([href="#"])
	// to target
	$('a.scroll-link').click(function( event ) {
			event.preventDefault();
			var target = $(this.hash);
			var topoffset = target.offset().top - 59;
				$('html,body').animate({
					scrollTop: topoffset
				}, 1000);
				return false;
	});

	// for 100%
	$('.scroll-100').click(function( event ) {
			event.preventDefault();
			var measurewhere = (window.innerHeight + 1);
				$('html,body').animate({
					scrollTop: measurewhere
				}, 1000);
				return false;
	});
	var sticked = false;
	// to top
	$('.scroll-top').click(function( event ) {
		if(sticked) {
				event.preventDefault();
					$('html,body').stop().animate({
						scrollTop: 0
					}, 2000);
					return false;
			}
	});

	// Fancybox
	$("[data-fancybox]").fancybox({
		 // Options will go here
	 });


	 if(window.location.hash) {
	   var hash = window.location.hash;
		 if(hash == '#message' ) {
			  $('#message').modal('show');
		 }
	 }

// Bootstrap Popovers
$(function () {
	$('[data-toggle="popover"]').popover();
});


// PRIVACY CHECKBOX HELPER
$("#PrivacyCheckAll").click(function(){
	if(this.checked) {
		$('.privacy-check').prop('checked', true);
	} else {
		$('.privacy-check').prop('checked', false);
	}

});



if($("#app_form").length) {

	var code_default = $("#app_form").attr( "action" );

	$("#code").keypress(function(){
		var code_search = $(this).val();
		$(this).removeClass('invalid');
		var code = code_default + code_search;
	 	$("#app_form").attr("action", code);
	});
	$("#code").change(function(){
		var code_search = $(this).val();
		$(this).removeClass('invalid');
		var code = code_default + code_search;
	 	$("#app_form").attr("action", code);
	});

	$("#code").focusout(function(){
		var code_search = $(this).val();
		$(this).removeClass('invalid');
		var code = code_default + code_search;
	 	$("#app_form").attr("action", code);
	});

	$("#code").blur(function(){
		var code_search = $(this).val();
		$(this).removeClass('invalid');
		var code = code_default + code_search;
	 	$("#app_form").attr("action", code);
	});

}


		$('.video').on('ended',function(){
      $('.sharebox').addClass('pop');
    });

		$("#close-share").click(function(){
			$('.sharebox').removeClass('pop');
		});


// END JQUERY
});



$(window).bind("load", function() {
	   $('.pageLoader').addClass('pageLoader__disable').delay(3000).queue(function(){
    $(this).css("display", "none" ).dequeue();
});
	});




// form validation
(function() {
	'use strict';
	window.addEventListener('load', function() {
		// Fetch all the forms we want to apply custom Bootstrap validation styles to
		var forms = document.getElementsByClassName('needs-validation');
		// Loop over them and prevent submission
		var validation = Array.prototype.filter.call(forms, function(form) {
			form.addEventListener('submit', function(event) {
				if (form.checkValidity() === false) {
					event.preventDefault();
					event.stopPropagation();
				}
				form.classList.add('was-validated');
			}, false);
		});
	}, false);
})();
